import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Box, Text } from '@chakra-ui/react';
import decode from 'jwt-decode';
import get from 'lodash/get';

import WideLogo from '../svgs/WideLogo';
import SEO from '../components/seo';
import { useSpinner } from '../contexts/SpinnerContext';
import {
  Section,
  Wrapper,
  FormContainer,
  Label,
  Input,
  Paragraph,
  ButtonWrapper,
  Button,
} from '../styles/new-password';

const UPDATE_PASSWORD = gql`
  mutation UPDATE_PASSWORD($id: ID!, $newPassword: String!) {
    changePassword(id: $id, newPassword: $newPassword) {
      id
    }
  }
`;

const ChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-_])[A-Za-z\d@$!%*#?&-_]{8,}$/,
      'Prüfen Sie Ihre Angabe. Ihr Passwort muss min. 8 Zeichen lang sein, sowie min. eine Zahl UND ein Sonderzeichen enthalten.'
    )
    .required('Du musst ein neues Passwort angeben'),
  newPasswordConfirm: Yup.string()
    .oneOf(
      [Yup.ref('newPassword'), null],
      'Leider stimmen Ihre Angaben nicht überein. Prüfen Sie diese und wiederholen Sie den Vorgang.'
    )
    .required('Du musst dein neues Passwort bestätigen'),
});

const NewPassword = () => {
  const { toggleSpinner } = useSpinner();
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState('LOADING_TOKEN');
  const [error, setError] = useState('');
  const [changePassword, { data }] = useMutation(UPDATE_PASSWORD);

  const handleSubmit = async ({ newPassword }) => {
    try {
      setError('');
      toggleSpinner(true);
      await changePassword({
        variables: {
          id: user.id,
          newPassword,
        },
      });
      setStatus('SUCCESS');
    } catch (error) {
      console.log(error);
      const errorName = get(error, 'graphQLErrors.0.message', '');
      setError(errorName);
    }
    toggleSpinner(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      const tokenUser = decode(token);
      setStatus('LOADED');
      setUser(tokenUser);
    } else {
      setStatus('NO_TOKEN_PROVIDED');
    }
  }, []);

  useEffect(() => {
    if (data && data.changePassword) {
      if (data.changePassword.id) {
        setStatus('SUCCESS');
      }
    }
  }, [data]);
  console.log(user);

  return (
    <>
      <SEO
        title="Neues Passwort setzen"
        meta={[
          {
            property: 'robots',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ newPassword: '', newPasswordConfirm: '' }}
        validationSchema={ChangePasswordSchema}>
        {({ values, touched, errors, handleChange, handleBlur }) => {
          return (
            <Section>
              <WideLogo className="mb-8" />
              {status === 'LOADED' ? (
                <Wrapper>
                  <FormContainer>
                    {error === 'TOKEN_EXPIRED' ? (
                      <Box backgroundColor="red.100" p="3" mb="6" textAlign="center" rounded="md">
                        <Text color="red.500">Du hast Dein Passwort schon zurückgesetzt.</Text>
                      </Box>
                    ) : null}
                    <div className="mb-4">
                      <Label htmlFor="password">Neues Passwort</Label>
                      <Input
                        errors={errors.newPassword}
                        touched={touched.newPassword}
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        value={values.newPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="******************"
                      />
                      {errors.newPassword && touched.newPassword ? (
                        <Paragraph>{errors.newPassword}</Paragraph>
                      ) : null}
                    </div>
                    <div className="mb-6">
                      <Label>Neues Passwort bestätigen</Label>
                      <Input
                        errorsNewPasswordConfirm={errors.newPasswordConfirm}
                        touchedNewPasswordConfirm={touched.newPasswordConfirm}
                        id="newPasswordConfirm"
                        name="newPasswordConfirm"
                        type="password"
                        value={values.newPasswordConfirm}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="******************"
                      />
                      {errors.newPasswordConfirm && touched.newPasswordConfirm ? (
                        <Paragraph>{errors.newPasswordConfirm}</Paragraph>
                      ) : null}
                    </div>
                    <ButtonWrapper>
                      <Button>Passwort speichern</Button>
                    </ButtonWrapper>
                  </FormContainer>
                </Wrapper>
              ) : null}
              {status === 'LOADING_TOKEN' ? <p>Passwort Formular wird vorbereitet...</p> : null}
              {status === 'NO_TOKEN_PROVIDED' ? (
                <p>Dein Link ist abgelaufen oder du hast einen fehlerhaften Link erhalten.</p>
              ) : null}
              {status === 'SUCCESS' ? (
                <p>Dein Passwort wurde erfolgreich geändert. Du kannst dich jetzt einloggen.</p>
              ) : null}
            </Section>
          );
        }}
      </Formik>
    </>
  );
};

export default NewPassword;
